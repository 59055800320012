@keyframes fadeInRight {
    0% {opacity: 0; transform: translateX(3em);}
    100% {opacity: 1; transform: translateX(0);}
}

@keyframes fadeInBottom {
    0% {opacity: 0; transform: translateY(10em);}
    100% {opacity: 1; transform: translateY(0);}
}

@keyframes float {
    0% {transform: translateY(0);}
    50% {transform: translateY(1.5em);}
    100% {transform: translateY(0);}
}

@keyframes balloon {
    0% {transform: scale(1);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1);}
}


