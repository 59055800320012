@use '../utils/' as u;

:root {
    --ff-inter: 'Inter', sans-serif;

    --fs-h1: 2.625rem;
    --fs-h2: 1.5rem;
    --fs-h3: 1.25rem;
    --fs-body: 1rem;
    --fs-small: .875rem;
    --fs-xs: .75rem;

    --fw-bold: 700;
    --fw-semi-bold: 600;
    --fw-medium: 500;
    --fw-regular: 400;
}

@include u.breakpoint-up(lg) {
    :root {
        --fs-h1: 3rem;
        --fs-h2: 2rem;
        --fs-h3: 1.5rem;
        --fs-body: 1.125rem;
        --fs-small: 1rem;
        --fs-xs: .875rem;
    }
}
