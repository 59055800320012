$breakpoint: (
    'xs' : '0',
    'sm' : '480px',
    'md' : '720px',
    'lg' : '960px',
    'xl' : '1200px'
);

@mixin breakpoint-up($size) {
    @media (min-width: map-get($breakpoint, $size)) {
        @content;
    }
}

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoint, $size)) {
        @content;
    }
}