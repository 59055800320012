@use '../utils/' as u;

.hero {
    overflow: hidden;
    position: relative;
    padding: 10em 0;
    background-color: hsl(var(--clr-primary-dark));
    background-image: linear-gradient(128.17deg, #000000 36.99%, rgba(0, 0, 0, 0) 76.97%), url(../images/hero-bg.png);
    background-size: cover;
    background-position: left top;
    clip-path: polygon(0 0, 109% 0, 100% 87%, 38% 100%, 0 95%);
    margin-bottom: 10em;

    .container {
        @include u.container;
        display: flex;
        justify-content: center;
        align-items: center;   
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 650px;
        color: hsl(var(--clr-white));
    }

    @include u.breakpoint-up(md) {
        .container {
            justify-content: flex-start;
        }

        .left {
           align-items: flex-start;
           text-align: left;
           margin-left: 6vw;
        }
    }
} 

.intro {
    line-height: 1.3;

    &__accent {
        background: linear-gradient(90deg, hsl(var(--clr-primary)) 65%, hsl(var(--clr-accent)) 90%);
        // background: linear-gradient(90deg, hsl(var(--clr-white)) 10%, hsl(var(--clr-primary)) 40%, hsl(var(--clr-accent)) 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
}

.intro-desc {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 5em ;

    @include u.breakpoint-up(md) {
        margin: unset;
        margin-bottom: 3em ;
    }
}

.hero__btn {
    font-family: var(--ff-inter);
    font-size: var(--fs-body);
    font-weight: var(--fw-bold);
    padding: .5em 1.75em;
    background-color: hsl(var(--clr-primary));
    border: 1px solid hsl(var(--clr-primary));
    color: hsl(var(--clr-white));
    margin-bottom: 1em;
    cursor: pointer;
    width: 80%;
    transition: all 400ms ease-in-out,
                transform 700ms ease-in-out 500ms, 
                opacity 700ms ease-in-out 500ms;
    
    &:hover {
        color: hsl(var(--clr-primary-dark));
        background-color: hsla(var(--clr-white), .85);
        border: 1px solid hsl(var(--clr-white));
    }

    &-icon {
        margin-left: .25em;
    }

    @include u.breakpoint-up(md) {
        width: unset;
    }
}

.hero__explore {
    position: relative;
    padding: .625em 2em;
    font-size: var(--fs-small);

    &::after {
        content: url(../images/arrow-down.svg);
        position: absolute;
        top: 50%;
        right: 0;
        animation: float 2s forwards ease-in-out infinite;
    }
}

