@use '../utils/' as u;

.contact {
    padding: 6em 0 10em;
    overflow: hidden;

    .container {
        @include u.container;
        position: relative;

        &::before {
            content: 'Let\0027s connect';
            position: absolute;
            top: -1.4em;
            left: 0;
            font-size: 2.7rem;
            color: hsla(var(--clr-black), .1);

            @include u.breakpoint-up(sm) {
                font-size: 4rem;
            }
        }
    }

    @include u.breakpoint-up(md) {
        &__actions {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 3em;
        }
    }

    @include u.breakpoint-up(lg) {
        &__actions {
            gap: 4em;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;

        & > * {
            margin-bottom: 1em;
            font-family: var(--ff-inter);
            font-size: var(--fs-body);
            padding: .5em;
            border: none;
            border-radius: .25em;
            color: hsl(var(--clr-white));
            background-color: hsl(var(--clr-primary-dark));

            &::placeholder {
                color: hsla(var(--clr-white), .325);
            }

            &:-webkit-autofill,
            &:-webkit-autofill,
            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px hsl(var(--clr-primary-dark)) inset !important;
                -webkit-box-shadow: 0 0 0 1000px hsl(var(--clr-primary-dark)) inset !important;
                -webkit-text-fill-color: white !important;
            }
        }

        .submit-btn {
            color: hsl(var(--clr-white));
            background-color: hsl(var(--clr-primary)); 
            border: 1px solid hsl(var(--clr-primary));          
            padding: .625em 1.5em;
            margin-bottom: 2em;
            cursor: pointer;
            transition: all 400ms ease-in-out;
            align-self: flex-start;

            &:hover {
                background-color: hsl(var(--clr-white));
                color: hsl(var(--clr-secondary));
                border: 1px solid hsl(var(--clr-secondary));   
                box-shadow: -4px 4px 5px -2px hsla(var(--clr-black), .5);
            }
        }
    }

    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 2em;
        }
    }

    &__icon {
        margin-right: 1em;
    }

    &__option {
        font-size: var(--fs-h3);
        font-weight: var(--fw-bold);
        position: relative;
        margin-bottom: 2em;
        display: inline-block;

        &::before {
            content: url(../images/arrow.svg);
            position: absolute;
            top: 40%;
            right: -100%;
        }
    }

    .social__icons {
        margin-bottom: 2em;

        .social__links {
            margin-right: 1.5em;

            &:last-child {
                margin-right: unset;
            }
        }

        .social__icon {
            color: hsl(var(--clr-black));
            font-size: 1.25rem;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            border: 1px solid hsla(var(--clr-gray-dark), .25);
            padding: .5em;
            transition: all 400ms ease-in-out;

            &:hover {
                color: hsl(var(--clr-white));
                background-color: hsl(var(--clr-primary));
            }
        }
    }

    .fadeInX {
        .social__links {
            animation: fadeInRight 2s ease-in-out both 1s;
        }
    
        @for $i from 1 through 4 {
            .social__links:nth-child(#{$i}) {
                animation-delay: $i * 0.15s;
            }
        }
    }
}

