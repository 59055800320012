@use '../utils/' as u;

.timeline {
    padding: 6em 0 4em;
    position: relative;
    margin-bottom: 5em;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        bottom: 0;
        background-color: hsl(var(--clr-primary-dark), 1);
        z-index: -1;
    }

    .container {
        @include u.container;
        position: relative;

        &::before {
            content: 'Timeline';
            position: absolute;
            top: -1.4em;
            left: 0;
            font-size: 2.7rem;
            color: hsla(var(--clr-black), .1);
        }

        @include u.breakpoint-up(sm) {
            &::before {
                font-size: 4rem;
            }
        }
    }

    .event {
        display: flex;
        max-width: 700px;
        margin: 0 auto;
        padding-bottom: 5em;

        &__icon {
            margin-right: 1em;
        }
        
        &__fa-icon {
            display: inline-block;
            width: 2em;
            height: 2em;
            padding: .75em;
            border-radius: 50%;
            border: 1px solid hsl(var(--clr-white));
            color: hsl(var(--clr-white));
            background-color: hsl(var(--clr-primary-dark));
            box-shadow: 0px 10px 10px -8px hsl(var(--clr-black));
        }

        &__line {
            display: inline-block;
            height: calc(100% + 1em);
            margin-left: 1.75em;
            border: 1px solid hsl(var(--clr-primary-dark));
            background-color: hsl(var(--clr-primary-dark));
        }

        &__info {
            padding: 2em;
            border: 1px solid hsla((var(--clr-secondary)), .25);
            background-color: hsl(var(--clr-white));
        }

        &__date {
            color: hsl(var(--clr-primary));
            font-weight: var(--fw-bold);
            background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        &__company {
            color: hsl(var(--clr-primary-dark));
        }
    }

}