h1, h2, h3, h4 {
    margin-top: 0;
    font-weight: var(--fw-bold)
}

h1 {font-size: var(--fs-h1);}
h2 {font-size: var(--fs-h2);}
h3 {font-size: var(--fs-h3);}
h4 {font-size: var(--fs-h4);}

p {margin-top: 0;}

ul {
    margin: 0;
    padding: 0;
  }
  
ul li {
    list-style-type: none;
    
}

a {
    text-decoration: none;
}
