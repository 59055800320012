:root {
    --clr-black: 0, 0%, 0%;
    --clr-white: 0, 0%, 100%;
    --clr-gray-light: 240, 15%, 95%;
    --clr-gray-dark: 220, 22%, 39%;
    --clr-primary: 190, 95%, 41%;
    --clr-primary-dark: 203, 66%, 17%;
    --clr-secondary: 198, 18%, 37%;
    --clr-accent: 347, 75%, 60%;
}

.temp {
    --clr-black: hsl(0, 0%, 0%);
    --clr-white: hsl(0, 0%, 100%);
    --clr-gray-light: hsl(240, 15%, 95%);
    --clr-gray-dark: hsl(220, 22%, 39%);
    --clr-primary: hsl(190, 95%, 41%);
    --clr-primary-dark: hsl(203, 66%, 17%);
    --clr-secondary: hsl(198, 18%, 37%);
    --clr-accent: hsl(347, 75%, 60%);
}

