.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: hsla(var(--clr-secondary), .75);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in-out;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: hsl(var(--clr-white));
    min-width: 300px;
    max-width: 450px;
    margin: 0 5vw;
    padding: 1em;
    position: relative;
    
    &__check-icon {
        border: 1px solid hsl(var(--clr-gray-light));
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: .5em;
        color: hsl(var(--clr-primary));
        margin-bottom: .25em;
    }

    &__sent {
        font-size: var(--fs-h2);
        font-weight: var(--fw-medium);
        margin-bottom: .5em;
    }

    &__message {
        text-align: center;
        font-size: var(--fs-small);
    }

    &__close-btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background-color: transparent;
        font-size: var(--fs-body);
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: all 200ms ease-in-out;

        &:hover {
            background-color: hsl(var(--clr-gray-light));
        }
    }
}