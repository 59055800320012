@use '../utils/' as u;

.projects {
    padding: 6em 0 10em;

    .container {
        position: relative;
        @include u.container;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2em;

        &::before {
            content: 'Projects';
            position: absolute;
            top: -1.4em;
            left: 0;
            font-size: 4rem;
            color: hsla(var(--clr-black), .1);

            @include u.breakpoint-up(md) {
                font-size: 4rem;
            }
        }
    }

    &__item {
        align-self: start;
        transition: box-shadow 300ms ease-in-out,
                    transform 700ms ease-in-out 500ms, 
                    opacity 700ms ease-in-out 500ms;

        &:hover {
            box-shadow: -10px 10px 10px -8px hsla(var(--clr-black), .75);
        }
    }

    .project__image-placeholder {
        overflow: hidden;
        background: hsl(var(--clr-secondary));
        position: relative;
        height: 230px;

        &:hover {
            .project__link {
                opacity: 1;

                &:hover {
                    color: hsl(var(--clr-primary));
                }
            }

            .project__image {
                transform: scale(1.2);
                opacity: .125;
            }
        }
    }
    
    .project__image {
        object-fit: cover;
        object-position: top;
        width: 100%;
        
        transition: all 400ms ease-in-out;
    }
    
    .project__link {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: hsl(var(--clr-white));
        opacity: 0;
        transition: all 400ms ease-in-out;
    }

    .link__icon {
        margin-left: .25em;
    }

    .content {
        padding: 1em;
        color: hsl(var(--clr-gray-light));
        background-color: hsl(var(--clr-primary-dark));
        height: calc(100% - 230px);

        @media (min-width: 744px) {
            min-height: 16.8em;
        } 

        @media (min-width: 790px) {
            min-height: 15.5em;
        } 
    }

    .main-title {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: .125em;
            width: 50%;
            background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
            transform: translateX(-15%);
        }
    }
    
    .desc {
        font-size: var(--fs-small);
        margin-bottom: -.25em;
    }

    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .read-more {
        font-size: var(--fs-small);
        color: hsl(var(--clr-primary));
        cursor: pointer;
        display: inline-block;
        margin-bottom: 1em;

        &:hover {
            color: hsla(var(--clr-primary), .5);
        }
    }

    .tags span{
        display: inline-block;
        font-size: .75rem;
        color: hsl(var(--clr-white));
        padding: .125em .75em;
        border-radius: .25em;
        background-color: hsla(var(--clr-primary), .85);
        margin-right: .5em;
        margin-bottom: .25em;
    }
}

