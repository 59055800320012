@use '../utils/' as u;

.header {
    color: hsl(var(--clr-white));
    box-shadow: 0px 5px 10px -8px hsla(var(--clr-white), .5);
    position: fixed;
    width: 100%;
    z-index: 10;
    transition: background-color 400ms ease-in-out;

    &.scrolledClass {
        background-color: hsl(var(--clr-black));
        --clr-black: 0, 0%, 100%;
        --clr-white: 0, 0%, 0%;

        .container {
            padding: .5em 0;
        }


        @include u.breakpoint-down(md) {
            .menu-btn.active {
                .burger::before, .burger::after {
                    background-color: hsl(var(--clr-black))
                }
            }
        }
    }

    .container {
        @include u.container;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0;
        transition: all 400ms ease-in-out;
    }

    @include u.breakpoint-down(md) {
        .menu-btn {
            display: block;
        }

        .nav {
            position: fixed;
            top: 0;
            right: 0;
            height: auto;
            width: 320px;
            color: hsl(var(--clr-black));
            background-color: hsla(var(--clr-white), .80);
            backdrop-filter: blur(5px);
            transform: translateX(100%);
            transition: all 400ms ease-in-out;
            z-index: 5;

            &.active {
                transform: translateX(0);

                .nav-list__item {
                    font-size: var(--fs-small);
                    animation: fadeInBottom 700ms ease-in-out both;
                }
    
                @for $i from 1 through 5 {
                    .nav-list__item:nth-child(#{$i}) {
                        animation-delay: $i * 0.05s;
                    }
                }
            }
        }

        .nav-list {
            flex-direction: column;
            justify-content: center;
            height: 100vh;

            &__item {
                margin-bottom: 4em;
                margin-left: 35%;

                .link {
                    display: inline-block;
                    text-align: center;
                }
            }
            
            .nav__link-icon {
                display: inline-block;
                font-size: 1.325rem;
                width: 1em;
                margin-right: .5em;
            }
        }
        
    }
}

.logo {
    margin-bottom: -.25em;
    cursor: pointer;
}

.menu-btn {
    display: none;
    position: relative;
    height: 26px;
    width: 26px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
    z-index: 10;

    .burger {
        position: absolute;
        width: 100%;
        height: 4px;
        top: 10px;
        border-radius: 1em;
        background-color: hsl(var(--clr-white));
        transition: all 400ms ease-in-out;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            top: -8px;
            border-radius: 1em;
            background-color: hsl(var(--clr-white));
            transition: all 400ms ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            left: 0;
            top: 8px;
            border-radius: 1em;
            background-color: hsl(var(--clr-white));
            transition: all 400ms ease-in-out;
        }
    }

    &.active {
         width: 26px;
         --clr-white: 0, 0%, 0%;
         .burger {
            background-color: transparent;
    
            &::before {
                transform: translateY(200%) rotate(45deg) ;
            }
    
            &::after {
                transform: translateY(-200%) rotate(-45deg) ;
            }
        }
    }
}

.nav-list {
    display: flex;

    &__item {
        font-size: var(--fs-small);
        font-weight: var(--fw-semi-bold);
        text-transform: capitalize;
        margin-left: 2em;

        .link {
            position: relative;
            padding: .5em 0;
            transition: transform 400ms ease-in-out;
            cursor: pointer;

            &::before {
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                height: .15em;
                width: 100%;
                transform: scaleX(0);
                background-color: hsl(var(--clr-primary));
                transition: transform 400ms ease-in-out;
                z-index: -1;
            }

            &:hover {
                
                &::before {
                    transform: scaleX(1);
                }
            }
            
            &.active {
                
                &::before {
                    transform: scaleX(1);
                }
            }
        }     
    }

    .nav__link-icon {
        display: none;
    }
}