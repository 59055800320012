@use '../utils/' as u;

.featured {  
    overflow: hidden;
    padding: 4.5em 0 10em;
    
    .container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        grid-template-areas: 
            "featured-right "
            "featured-left ";

        &::before {
            content: 'Featured';
            position: absolute;
            top: -1.4em;
            left: 6vw;
            font-size: 4rem;
            color: hsla(var(--clr-black), .1);
        }

        .left {
            grid-area: featured-left;
            background-color: hsla(var(--clr-white), .5);
            backdrop-filter: blur(5px);
            margin-left: 6vw;
            margin-right: 6vw;
            position: relative;
            border-radius: .25em;
            display: flex;
            flex-direction: column;
        }
    
        .right {
            grid-area: featured-right;
        }

        @include u.breakpoint-up(md) {
            @include u.container;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "featured-left featured-right";

            &::before {
                top: -1.4em;
                left: 4em;
            }

            .square-bg {
                position: absolute;
                width: 140px;
                height: 140px;
                top: -10%;
                left: -2%;
    
                &::before {
                    content: url(../images/square-bg.svg);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
    
                &::after {
                    content: url(../images/square-small-bg.svg);
                    position: absolute;
                    top: -25%;
                    right: -25%;
                }
            }
    
            .left {
                padding: 1.25em;
                align-self: center;
                width: 110%;
                position: relative;
                z-index: 5;
                box-shadow: -6px 10px 10px -8px hsla(var(--clr-black), .5);
                margin-left: unset;
                margin-right: unset;
            }
    
            .right {
                align-self: center;
                width: 140%;
            }

            .photo-placeholder {
                transform: translate(-20%, 0%);
            }
    
            .featured__photo {
                margin-bottom: unset;               
            }
        }
    }

    &__title {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: .175em;
            width: 50%;
            background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
            transform: translateX(-20%);
        }
    }

    &__desc {
        margin-bottom: 1em;
    }

    &__tags span {
        display: inline-block;
        font-size: var(--fs-xs);
        color: hsl(var(--clr-white));
        padding: .125em .75em;
        border-radius: .25em;
        background-color: hsla(var(--clr-primary-dark), .85);
        margin-right: .5em;
        margin-bottom: .25em;
    }
    
    &__link {
        font-weight: var(--fw-medium);
        color: hsl(var(--clr-white));
        background-color: hsl(var(--clr-primary));
        padding: .5em 1.25em;
        margin-top: 1.5em;
        margin-right: auto;
        border: 1px solid transparent;
        transition: all 400ms ease-in-out;
    
        &:hover {
            background-color: hsl(var(--clr-white));
            color: hsl(var(--clr-secondary));
            box-shadow: -4px 4px 5px -2px hsla(var(--clr-black), .5);
        }

        @include u.breakpoint-up(md) {
            margin-right: unset;
            margin-left: auto;
        }
    }

    .link__icon {
        font-size: 1.25rem;
        margin-left: .25em;
    }
    
    &__photo {
        width: 100%;
        min-height: 400px;
        max-height: 450px;
        object-fit: cover;
        object-position: right;
        transition: all 400ms ease-in-out;
    } 
}

