@use '../utils/' as u;

.alert {
    position: fixed;
    top: 4em;
    right: 0;
    min-width: 320px;
    color: hsl(var(--clr-white));
    background-color: hsl(var(--clr-primary));
    border-radius: .25em;
    border-left: 5px solid hsl(var(--clr-secondary-light));
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 5;
    padding: 1.25em;

    @include u.breakpoint-up(md) {
        min-width: 350px;
    }
}