:root {
  --ff-inter: "Inter", sans-serif;
  --fs-h1: 2.625rem;
  --fs-h2: 1.5rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
  --fs-small: .875rem;
  --fs-xs: .75rem;
  --fw-bold: 700;
  --fw-semi-bold: 600;
  --fw-medium: 500;
  --fw-regular: 400;
}

@media (min-width: 960px) {
  :root {
    --fs-h1: 3rem;
    --fs-h2: 2rem;
    --fs-h3: 1.5rem;
    --fs-body: 1.125rem;
    --fs-small: 1rem;
    --fs-xs: .875rem;
  }
}
:root {
  --clr-black: 0, 0%, 0%;
  --clr-white: 0, 0%, 100%;
  --clr-gray-light: 240, 15%, 95%;
  --clr-gray-dark: 220, 22%, 39%;
  --clr-primary: 190, 95%, 41%;
  --clr-primary-dark: 203, 66%, 17%;
  --clr-secondary: 198, 18%, 37%;
  --clr-accent: 347, 75%, 60%;
}

.temp {
  --clr-black: hsl(0, 0%, 0%);
  --clr-white: hsl(0, 0%, 100%);
  --clr-gray-light: hsl(240, 15%, 95%);
  --clr-gray-dark: hsl(220, 22%, 39%);
  --clr-primary: hsl(190, 95%, 41%);
  --clr-primary-dark: hsl(203, 66%, 17%);
  --clr-secondary: hsl(198, 18%, 37%);
  --clr-accent: hsl(347, 75%, 60%);
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--ff-inter);
  font-size: var(--fs-body);
  font-weight: var(--fw-regular);
  color: hsl(var(--clr-black));
  line-height: 1.6;
}

img {
  display: block;
  max-width: 100%;
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: var(--fw-bold);
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

h4 {
  font-size: var(--fs-h4);
}

p {
  margin-top: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(3em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(3em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(10em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translateY(10em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1.5em);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1.5em);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes balloon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes balloon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.fadeLeft {
  opacity: 0;
  transform: translateX(-200px);
  transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;
}

.fadeRight {
  opacity: 0;
  transform: translateX(200px);
  transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;
}

.fadeBottom {
  opacity: 0;
  transform: translateY(200px);
  transition: transform 700ms ease-in-out, opacity 700ms ease-in-out;
}

.fadeInY {
  opacity: 1;
  transform: translateY(0);
}

.fadeInX {
  opacity: 1;
  transform: translateX(0);
}

.fade {
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.fadeInScale {
  opacity: 1;
  transition: opacity 700ms ease-in-out;
  -webkit-animation: balloon 700ms forwards ease-in-out 500ms;
          animation: balloon 700ms forwards ease-in-out 500ms;
}

.header {
  color: hsl(var(--clr-white));
  box-shadow: 0px 5px 10px -8px hsla(var(--clr-white), 0.5);
  position: fixed;
  width: 100%;
  z-index: 10;
  transition: background-color 400ms ease-in-out;
}
.header.scrolledClass {
  background-color: hsl(var(--clr-black));
  --clr-black: 0, 0%, 100%;
  --clr-white: 0, 0%, 0%;
}
.header.scrolledClass .container {
  padding: 0.5em 0;
}
@media (max-width: 720px) {
  .header.scrolledClass .menu-btn.active .burger::before, .header.scrolledClass .menu-btn.active .burger::after {
    background-color: hsl(var(--clr-black));
  }
}
.header .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  transition: all 400ms ease-in-out;
}
@media (min-width: 720px) {
  .header .container {
    width: min(85%, 1200px);
  }
}
@media (max-width: 720px) {
  .header .menu-btn {
    display: block;
  }
  .header .nav {
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: 320px;
    color: hsl(var(--clr-black));
    background-color: hsla(var(--clr-white), 0.8);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    transform: translateX(100%);
    transition: all 400ms ease-in-out;
    z-index: 5;
  }
  .header .nav.active {
    transform: translateX(0);
  }
  .header .nav.active .nav-list__item {
    font-size: var(--fs-small);
    -webkit-animation: fadeInBottom 700ms ease-in-out both;
            animation: fadeInBottom 700ms ease-in-out both;
  }
  .header .nav.active .nav-list__item:nth-child(1) {
    -webkit-animation-delay: 0.05s;
            animation-delay: 0.05s;
  }
  .header .nav.active .nav-list__item:nth-child(2) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  .header .nav.active .nav-list__item:nth-child(3) {
    -webkit-animation-delay: 0.15s;
            animation-delay: 0.15s;
  }
  .header .nav.active .nav-list__item:nth-child(4) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .header .nav.active .nav-list__item:nth-child(5) {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  .header .nav-list {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
  .header .nav-list__item {
    margin-bottom: 4em;
    margin-left: 35%;
  }
  .header .nav-list__item .link {
    display: inline-block;
    text-align: center;
  }
  .header .nav-list .nav__link-icon {
    display: inline-block;
    font-size: 1.325rem;
    width: 1em;
    margin-right: 0.5em;
  }
}

.logo {
  margin-bottom: -0.25em;
  cursor: pointer;
}

.menu-btn {
  display: none;
  position: relative;
  height: 26px;
  width: 26px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  z-index: 10;
}
.menu-btn .burger {
  position: absolute;
  width: 100%;
  height: 4px;
  top: 10px;
  border-radius: 1em;
  background-color: hsl(var(--clr-white));
  transition: all 400ms ease-in-out;
}
.menu-btn .burger::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  top: -8px;
  border-radius: 1em;
  background-color: hsl(var(--clr-white));
  transition: all 400ms ease-in-out;
}
.menu-btn .burger::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  left: 0;
  top: 8px;
  border-radius: 1em;
  background-color: hsl(var(--clr-white));
  transition: all 400ms ease-in-out;
}
.menu-btn.active {
  width: 26px;
  --clr-white: 0, 0%, 0%;
}
.menu-btn.active .burger {
  background-color: transparent;
}
.menu-btn.active .burger::before {
  transform: translateY(200%) rotate(45deg);
}
.menu-btn.active .burger::after {
  transform: translateY(-200%) rotate(-45deg);
}

.nav-list {
  display: flex;
}
.nav-list__item {
  font-size: var(--fs-small);
  font-weight: var(--fw-semi-bold);
  text-transform: capitalize;
  margin-left: 2em;
}
.nav-list__item .link {
  position: relative;
  padding: 0.5em 0;
  transition: transform 400ms ease-in-out;
  cursor: pointer;
}
.nav-list__item .link::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 0.15em;
  width: 100%;
  transform: scaleX(0);
  background-color: hsl(var(--clr-primary));
  transition: transform 400ms ease-in-out;
  z-index: -1;
}
.nav-list__item .link:hover::before {
  transform: scaleX(1);
}
.nav-list__item .link.active::before {
  transform: scaleX(1);
}
.nav-list .nav__link-icon {
  display: none;
}

.hero {
  overflow: hidden;
  position: relative;
  padding: 10em 0;
  background-color: hsl(var(--clr-primary-dark));
  background-image: linear-gradient(128.17deg, #000000 36.99%, rgba(0, 0, 0, 0) 76.97%), url(../images/hero-bg.png);
  background-size: cover;
  background-position: left top;
  -webkit-clip-path: polygon(0 0, 109% 0, 100% 87%, 38% 100%, 0 95%);
          clip-path: polygon(0 0, 109% 0, 100% 87%, 38% 100%, 0 95%);
  margin-bottom: 10em;
}
.hero .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 720px) {
  .hero .container {
    width: min(85%, 1200px);
  }
}
.hero .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 650px;
  color: hsl(var(--clr-white));
}
@media (min-width: 720px) {
  .hero .container {
    justify-content: flex-start;
  }
  .hero .left {
    align-items: flex-start;
    text-align: left;
    margin-left: 6vw;
  }
}

.intro {
  line-height: 1.3;
}
.intro__accent {
  background: linear-gradient(90deg, hsl(var(--clr-primary)) 65%, hsl(var(--clr-accent)) 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.intro-desc {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 5em;
}
@media (min-width: 720px) {
  .intro-desc {
    margin: unset;
    margin-bottom: 3em;
  }
}

.hero__btn {
  font-family: var(--ff-inter);
  font-size: var(--fs-body);
  font-weight: var(--fw-bold);
  padding: 0.5em 1.75em;
  background-color: hsl(var(--clr-primary));
  border: 1px solid hsl(var(--clr-primary));
  color: hsl(var(--clr-white));
  margin-bottom: 1em;
  cursor: pointer;
  width: 80%;
  transition: all 400ms ease-in-out, transform 700ms ease-in-out 500ms, opacity 700ms ease-in-out 500ms;
}
.hero__btn:hover {
  color: hsl(var(--clr-primary-dark));
  background-color: hsla(var(--clr-white), 0.85);
  border: 1px solid hsl(var(--clr-white));
}
.hero__btn-icon {
  margin-left: 0.25em;
}
@media (min-width: 720px) {
  .hero__btn {
    width: unset;
  }
}

.hero__explore {
  position: relative;
  padding: 0.625em 2em;
  font-size: var(--fs-small);
}
.hero__explore::after {
  content: url(../images/arrow-down.svg);
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-animation: float 2s forwards ease-in-out infinite;
          animation: float 2s forwards ease-in-out infinite;
}

.featured {
  overflow: hidden;
  padding: 4.5em 0 10em;
}
.featured .container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  grid-template-areas: "featured-right " "featured-left ";
}
.featured .container::before {
  content: "Featured";
  position: absolute;
  top: -1.4em;
  left: 6vw;
  font-size: 4rem;
  color: hsla(var(--clr-black), 0.1);
}
.featured .container .left {
  grid-area: featured-left;
  background-color: hsla(var(--clr-white), 0.5);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  margin-left: 6vw;
  margin-right: 6vw;
  position: relative;
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
}
.featured .container .right {
  grid-area: featured-right;
}
@media (min-width: 720px) {
  .featured .container {
    width: min(88%, 1200px);
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "featured-left featured-right";
  }
}
@media (min-width: 720px) and (min-width: 720px) {
  .featured .container {
    width: min(85%, 1200px);
  }
}
@media (min-width: 720px) {
  .featured .container::before {
    top: -1.4em;
    left: 4em;
  }
}
@media (min-width: 720px) {
  .featured .container .square-bg {
    position: absolute;
    width: 140px;
    height: 140px;
    top: -10%;
    left: -2%;
  }
  .featured .container .square-bg::before {
    content: url(../images/square-bg.svg);
    position: absolute;
    top: 0;
    left: 0;
  }
  .featured .container .square-bg::after {
    content: url(../images/square-small-bg.svg);
    position: absolute;
    top: -25%;
    right: -25%;
  }
}
@media (min-width: 720px) {
  .featured .container .left {
    padding: 1.25em;
    align-self: center;
    width: 110%;
    position: relative;
    z-index: 5;
    box-shadow: -6px 10px 10px -8px hsla(var(--clr-black), 0.5);
    margin-left: unset;
    margin-right: unset;
  }
}
@media (min-width: 720px) {
  .featured .container .right {
    align-self: center;
    width: 140%;
  }
}
@media (min-width: 720px) {
  .featured .container .photo-placeholder {
    transform: translate(-20%, 0%);
  }
}
@media (min-width: 720px) {
  .featured .container .featured__photo {
    margin-bottom: unset;
  }
}
.featured__title {
  position: relative;
}
.featured__title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.175em;
  width: 50%;
  background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
  transform: translateX(-20%);
}
.featured__desc {
  margin-bottom: 1em;
}
.featured__tags span {
  display: inline-block;
  font-size: var(--fs-xs);
  color: hsl(var(--clr-white));
  padding: 0.125em 0.75em;
  border-radius: 0.25em;
  background-color: hsla(var(--clr-primary-dark), 0.85);
  margin-right: 0.5em;
  margin-bottom: 0.25em;
}
.featured__link {
  font-weight: var(--fw-medium);
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary));
  padding: 0.5em 1.25em;
  margin-top: 1.5em;
  margin-right: auto;
  border: 1px solid transparent;
  transition: all 400ms ease-in-out;
}
.featured__link:hover {
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-secondary));
  box-shadow: -4px 4px 5px -2px hsla(var(--clr-black), 0.5);
}
@media (min-width: 720px) {
  .featured__link {
    margin-right: unset;
    margin-left: auto;
  }
}
.featured .link__icon {
  font-size: 1.25rem;
  margin-left: 0.25em;
}
.featured__photo {
  width: 100%;
  min-height: 400px;
  max-height: 450px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: right;
     object-position: right;
  transition: all 400ms ease-in-out;
}

.skills {
  padding: 4em 0 10em;
}
.skills .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  position: relative;
  padding: 0 2em;
}
@media (min-width: 720px) {
  .skills .container {
    width: min(85%, 1200px);
  }
}
.skills .container::before {
  content: "Skills";
  position: absolute;
  top: -1.4em;
  left: 0;
  width: 100%;
  font-size: 4rem;
  color: hsla(var(--clr-black), 0.1);
}
@media (min-width: 720px) {
  .skills .container::before::before {
    font-size: 8rem;
  }
}
@media (min-width: 480px) {
  .skills .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 1.5em;
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  gap: 1em;
}

.card {
  padding: 2em 1em;
  width: 100%;
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.card:hover::before {
  -webkit-clip-path: circle(70%);
          clip-path: circle(70%);
}

.brand {
  width: 4em;
  height: 4em;
  color: black;
  background-color: hsl(var(--clr-white));
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.brand__icon {
  font-size: 3rem;
}
.brand__name {
  font-size: var(--fs-h3);
  font-weight: var(--fw-medium);
}
.brand__desc {
  font-size: var(--fs-small);
  margin-bottom: 0;
}
.brand__html {
  color: #e34f26;
}
.brand__css {
  color: #002561;
}
.brand__js {
  color: #f0db4f;
}
.brand__react {
  color: #00d8ff;
}
.brand__sass {
  color: #cc6699;
}
.brand__bootstrap {
  color: #563d7c;
}
.brand__git {
  color: #f34f29;
}
.brand__nodejs {
  color: #44883e;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.read-more {
  font-size: var(--fs-small);
  color: hsla(var(--clr-primary), 0.75);
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1em;
  align-self: flex-start;
}
.read-more:hover {
  color: hsla(var(--clr-primary), 0.5);
}

.projects {
  padding: 6em 0 10em;
}
.projects .container {
  position: relative;
  width: min(88%, 1200px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2em;
}
@media (min-width: 720px) {
  .projects .container {
    width: min(85%, 1200px);
  }
}
.projects .container::before {
  content: "Projects";
  position: absolute;
  top: -1.4em;
  left: 0;
  font-size: 4rem;
  color: hsla(var(--clr-black), 0.1);
}
@media (min-width: 720px) {
  .projects .container::before {
    font-size: 4rem;
  }
}
.projects__item {
  align-self: start;
  transition: box-shadow 300ms ease-in-out, transform 700ms ease-in-out 500ms, opacity 700ms ease-in-out 500ms;
}
.projects__item:hover {
  box-shadow: -10px 10px 10px -8px hsla(var(--clr-black), 0.75);
}
.projects .project__image-placeholder {
  overflow: hidden;
  background: hsl(var(--clr-secondary));
  position: relative;
  height: 230px;
}
.projects .project__image-placeholder:hover .project__link {
  opacity: 1;
}
.projects .project__image-placeholder:hover .project__link:hover {
  color: hsl(var(--clr-primary));
}
.projects .project__image-placeholder:hover .project__image {
  transform: scale(1.2);
  opacity: 0.125;
}
.projects .project__image {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  width: 100%;
  transition: all 400ms ease-in-out;
}
.projects .project__link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: hsl(var(--clr-white));
  opacity: 0;
  transition: all 400ms ease-in-out;
}
.projects .link__icon {
  margin-left: 0.25em;
}
.projects .content {
  padding: 1em;
  color: hsl(var(--clr-gray-light));
  background-color: hsl(var(--clr-primary-dark));
  height: calc(100% - 230px);
}
@media (min-width: 744px) {
  .projects .content {
    min-height: 16.8em;
  }
}
@media (min-width: 790px) {
  .projects .content {
    min-height: 15.5em;
  }
}
.projects .main-title {
  position: relative;
}
.projects .main-title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.125em;
  width: 50%;
  background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
  transform: translateX(-15%);
}
.projects .desc {
  font-size: var(--fs-small);
  margin-bottom: -0.25em;
}
.projects .line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.projects .read-more {
  font-size: var(--fs-small);
  color: hsl(var(--clr-primary));
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1em;
}
.projects .read-more:hover {
  color: hsla(var(--clr-primary), 0.5);
}
.projects .tags span {
  display: inline-block;
  font-size: 0.75rem;
  color: hsl(var(--clr-white));
  padding: 0.125em 0.75em;
  border-radius: 0.25em;
  background-color: hsla(var(--clr-primary), 0.85);
  margin-right: 0.5em;
  margin-bottom: 0.25em;
}

.timeline {
  padding: 6em 0 4em;
  position: relative;
  margin-bottom: 5em;
  overflow: hidden;
}
.timeline::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  bottom: 0;
  background-color: hsl(var(--clr-primary-dark), 1);
  z-index: -1;
}
.timeline .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  position: relative;
}
@media (min-width: 720px) {
  .timeline .container {
    width: min(85%, 1200px);
  }
}
.timeline .container::before {
  content: "Timeline";
  position: absolute;
  top: -1.4em;
  left: 0;
  font-size: 2.7rem;
  color: hsla(var(--clr-black), 0.1);
}
@media (min-width: 480px) {
  .timeline .container::before {
    font-size: 4rem;
  }
}
.timeline .event {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 5em;
}
.timeline .event__icon {
  margin-right: 1em;
}
.timeline .event__fa-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  padding: 0.75em;
  border-radius: 50%;
  border: 1px solid hsl(var(--clr-white));
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary-dark));
  box-shadow: 0px 10px 10px -8px hsl(var(--clr-black));
}
.timeline .event__line {
  display: inline-block;
  height: calc(100% + 1em);
  margin-left: 1.75em;
  border: 1px solid hsl(var(--clr-primary-dark));
  background-color: hsl(var(--clr-primary-dark));
}
.timeline .event__info {
  padding: 2em;
  border: 1px solid hsla(var(--clr-secondary), 0.25);
  background-color: hsl(var(--clr-white));
}
.timeline .event__date {
  color: hsl(var(--clr-primary));
  font-weight: var(--fw-bold);
  background: linear-gradient(90deg, hsl(var(--clr-primary)) 45%, hsl(var(--clr-accent)) 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.timeline .event__company {
  color: hsl(var(--clr-primary-dark));
}

.contact {
  padding: 6em 0 10em;
  overflow: hidden;
}
.contact .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  position: relative;
}
@media (min-width: 720px) {
  .contact .container {
    width: min(85%, 1200px);
  }
}
.contact .container::before {
  content: "Let's connect";
  position: absolute;
  top: -1.4em;
  left: 0;
  font-size: 2.7rem;
  color: hsla(var(--clr-black), 0.1);
}
@media (min-width: 480px) {
  .contact .container::before {
    font-size: 4rem;
  }
}
@media (min-width: 720px) {
  .contact__actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3em;
  }
}
@media (min-width: 960px) {
  .contact__actions {
    gap: 4em;
  }
}
.contact__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}
.contact__form > * {
  margin-bottom: 1em;
  font-family: var(--ff-inter);
  font-size: var(--fs-body);
  padding: 0.5em;
  border: none;
  border-radius: 0.25em;
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary-dark));
}
.contact__form > *::-moz-placeholder {
  color: hsla(var(--clr-white), 0.325);
}
.contact__form > *:-ms-input-placeholder {
  color: hsla(var(--clr-white), 0.325);
}
.contact__form > *::placeholder {
  color: hsla(var(--clr-white), 0.325);
}
.contact__form > *:-webkit-autofill, .contact__form > *:-webkit-autofill, .contact__form > *:-webkit-autofill {
  box-shadow: 0 0 0 1000px hsl(var(--clr-primary-dark)) inset !important;
  -webkit-box-shadow: 0 0 0 1000px hsl(var(--clr-primary-dark)) inset !important;
  -webkit-text-fill-color: white !important;
}
.contact__form .submit-btn {
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary));
  border: 1px solid hsl(var(--clr-primary));
  padding: 0.625em 1.5em;
  margin-bottom: 2em;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  align-self: flex-start;
}
.contact__form .submit-btn:hover {
  background-color: hsl(var(--clr-white));
  color: hsl(var(--clr-secondary));
  border: 1px solid hsl(var(--clr-secondary));
  box-shadow: -4px 4px 5px -2px hsla(var(--clr-black), 0.5);
}
.contact__info {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.contact__info:last-of-type {
  margin-bottom: 2em;
}
.contact__icon {
  margin-right: 1em;
}
.contact__option {
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
  position: relative;
  margin-bottom: 2em;
  display: inline-block;
}
.contact__option::before {
  content: url(../images/arrow.svg);
  position: absolute;
  top: 40%;
  right: -100%;
}
.contact .social__icons {
  margin-bottom: 2em;
}
.contact .social__icons .social__links {
  margin-right: 1.5em;
}
.contact .social__icons .social__links:last-child {
  margin-right: unset;
}
.contact .social__icons .social__icon {
  color: hsl(var(--clr-black));
  font-size: 1.25rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid hsla(var(--clr-gray-dark), 0.25);
  padding: 0.5em;
  transition: all 400ms ease-in-out;
}
.contact .social__icons .social__icon:hover {
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary));
}
.contact .fadeInX .social__links {
  -webkit-animation: fadeInRight 2s ease-in-out both 1s;
          animation: fadeInRight 2s ease-in-out both 1s;
}
.contact .fadeInX .social__links:nth-child(1) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.contact .fadeInX .social__links:nth-child(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.contact .fadeInX .social__links:nth-child(3) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.contact .fadeInX .social__links:nth-child(4) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.footer {
  background-color: hsl(var(--clr-primary-dark));
  -webkit-clip-path: polygon(0 10%, 41% 0, 100% 26%, 100% 100%, 0 100%);
          clip-path: polygon(0 10%, 41% 0, 100% 26%, 100% 100%, 0 100%);
}
.footer .container {
  width: min(88%, 1200px);
  margin: 0 auto;
  text-align: center;
  padding: 4em 0 3em;
}
@media (min-width: 720px) {
  .footer .container {
    width: min(85%, 1200px);
  }
}
.footer__copyright {
  font-size: 1.25rem;
  color: hsla(var(--clr-white), 0.95);
  font-weight: var(--fw-regular);
  margin-bottom: 0;
}

.alert {
  position: fixed;
  top: 4em;
  right: 0;
  min-width: 320px;
  color: hsl(var(--clr-white));
  background-color: hsl(var(--clr-primary));
  border-radius: 0.25em;
  border-left: 5px solid hsl(var(--clr-secondary-light));
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 5;
  padding: 1.25em;
}
@media (min-width: 720px) {
  .alert {
    min-width: 350px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(var(--clr-secondary), 0.75);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsl(var(--clr-white));
  min-width: 300px;
  max-width: 450px;
  margin: 0 5vw;
  padding: 1em;
  position: relative;
}
.modal__check-icon {
  border: 1px solid hsl(var(--clr-gray-light));
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0.5em;
  color: hsl(var(--clr-primary));
  margin-bottom: 0.25em;
}
.modal__sent {
  font-size: var(--fs-h2);
  font-weight: var(--fw-medium);
  margin-bottom: 0.5em;
}
.modal__message {
  text-align: center;
  font-size: var(--fs-small);
}
.modal__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  font-size: var(--fs-body);
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.modal__close-btn:hover {
  background-color: hsl(var(--clr-gray-light));
}/*# sourceMappingURL=styles.css.map */