
@use '../utils/' as u;

.skills {
    padding: 4em 0 10em;
    
    .container {
        @include u.container;
        position: relative;
        padding: 0 2em;

        &::before {
            content: 'Skills';
            position: absolute;
            top: -1.4em;
            left: 0;
            width: 100%;
            font-size: 4rem;
            color: hsla(var(--clr-black), .1);

            @include u.breakpoint-up(md) {
                &::before {
                    font-size: 8rem;
        
                }
            }
        }
    }

    @include u.breakpoint-up(sm) {
        .cards-container {
            grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
            gap: 1.5em;
        }
    }
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    gap: 1em;
}

.card {
    padding: 2em 1em;
    width: 100%;
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:hover {
        &::before {
            clip-path: circle(70%);
        }
    }
}

.brand {
    width: 4em;
    height: 4em;
    color: black;
    background-color: hsl(var(--clr-white));
    margin-bottom: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    
    &__icon {
        font-size: 3rem;
    }

    &__name {
        font-size: var(--fs-h3);
        font-weight: var(--fw-medium);
    }
    
    &__desc {
        font-size: var(--fs-small);
        margin-bottom: 0;
    }

    &__html {
        color: #e34f26;
    }

    &__css {
        color: #002561;
    }
    
    &__js {
        color: #f0db4f;
    }

    &__react {
        color: #00d8ff;
    }

    &__sass {
        color: #cc6699;
    }

    &__bootstrap {
        color: #563d7c;
    }

    &__git {
        color: #f34f29;
    }

    &__nodejs {
        color: #44883e;
    }
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.read-more {
    font-size: var(--fs-small);
    color: hsla(var(--clr-primary), .75);
    cursor: pointer;
    display: inline-block;
    margin-bottom: 1em;
    align-self: flex-start;

    &:hover {
        color: hsla(var(--clr-primary), .5);
    }
}


